import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const privacyPolicyData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicPrivacyPolicy {
        _previewable
        uid
        data {
          title {
            html
          }
          page_text {
            html
          }
          body {
            ... on PrismicPrivacyPolicyBodyBook {
              id
              primary {
                button_text
                link {
                  link_type
                  url
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const { uid, data } = mergedData.prismicPrivacyPolicy
  const { body, title, page_text } = data

  return {
    uid,
    title,
    page_text,
    bannerData: body[0] ? body[0].primary : undefined,
  }
}
