import React from "react"
import Layout from "../components/layout/layout"
import { privacyPolicyData } from "../hooks/usePrivacyPolicyData"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"

const PrivacyPolicy = ({ data: previewData }) => {
  const { uid, title, page_text, bannerData } = privacyPolicyData(previewData)
  return (
    <Layout bookBannerData={bannerData}>
      <SEO title={uid} />
      <PageHeader title={title} />
      <PrivacyPolicyText dangerouslySetInnerHTML={{ __html: page_text.html }} />
    </Layout>
  )
}

export default withPreview(PrivacyPolicy)

const PrivacyPolicyText = styled.div`
  max-width: 80%;
  margin: 0 auto 40px auto;
  p,
  ul,
  li {
    color: var(--primary-text);
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .block-img {
    width: fit-content;
    margin: 0 auto;
    padding: 40px 0;
  }
  img {
    max-width: 500px;
    max-height: 500px;
  }
  iframe {
    padding: 40px 0;
    width: 100%;
    min-height: 500px;
  }
  a {
    :visited {
      color: var(--primary-text);
    }
  }
`
